import logo from "../assets/images/logo.webp";
import zigzag from "../assets/images/zigzag.png";

// logos
import unbound from "../assets/images/logos/unbound.png";
import vnv from "../assets/images/logos/VnV.png";
import informatic from "../assets/images/logos/informatic.png";
import ruchi from "../assets/images/logos/ruchi.png";
import tallyon from "../assets/images/logos/tallyon.png";

// Home
import homeLanding from "../assets/images/home/homelanding.webp";
import recapHand from "../assets/images/home/recap.webp";
import reward from "../assets/images/home/reward.png";
import successSketch from "../assets/images/successsketch.svg";
import fnf from "../assets/images/fnf.svg";
import loginBanner from "../assets/images/loginbanner.png";
import getApp from "../assets/images/home/apps.webp";
import beSpoke from "../assets/images/home/Tailormade.webp";

// Blog
import blog from "../assets/images/blogs/blog.jpg";

import blogA from "../assets/images/blogs/blogA.jpg";
import blogB from "../assets/images/blogs/blogB.jpg";
import blogC from "../assets/images/blogs/blogC.jpg";
import blogD from "../assets/images/blogs/blogD.jpg";
import blogE from "../assets/images/blogs/blogE.jpg";
import blogF from "../assets/images/blogs/blogF.jpg";
import blogG from "../assets/images/blogs/blogG.jpg";
import blogH from "../assets/images/blogs/blogH.jpg";
import blogI from "../assets/images/blogs/blogI.jpg";
import blogJ from "../assets/images/blogs/blogJ.jpg";
import blogK from "../assets/images/blogs/blogK.jpg";
import blogL from "../assets/images/blogs/blogL.jpg";
import corner from "../assets/images/blogs/corner.svg";

import viewBlog from "../assets/images/blogdetail.png";

//Carers
import career from "../assets/images/career/career.svg";
import careerArrow from "../assets/images/career/careerarrow.svg";
import imgSlideA from "../assets/images/career/imgSlideA.webp";
import imgSlideB from "../assets/images/career/imgSlideB.webp";
import imgSlideC from "../assets/images/career/imgSlideC.webp";
import imgSlideD from "../assets/images/career/imgSlideD.webp";
import imgSlideE from "../assets/images/career/imgSlideE.webp";

// About

import aboutA from "../assets/images/about/About1.webp";
import aboutB from "../assets/images/about/About2.webp";
import aboutC from "../assets/images/about/About3.webp";

import aboutLandingA from "../assets/images/about/aboutLandingA.webp";
import aboutLandingB from "../assets/images/about/aboutLandingB.webp";
import aboutLandingC from "../assets/images/about/aboutLandingC.webp";

import aboutLandingArrowA from "../assets/images/about/aboutLandingArrowA.svg";
import aboutLandingArrowB from "../assets/images/about/aboutLandingArrowB.svg";
import aboutLandingArrowC from "../assets/images/about/aboutLandingArrowC.svg";

import storyA from "../assets/images/about/storyA.webp";
import storyB from "../assets/images/about/storyB.webp";
import storyC from "../assets/images/about/storyC.webp";

import schoolA from "../assets/images/about/schoolA.svg";
import schoolB from "../assets/images/about/schoolB.svg";
import schoolC from "../assets/images/about/schoolC.svg";
import schoolD from "../assets/images/about/schoolD.svg";
import schoolE from "../assets/images/about/schoolE.svg";
import schoolF from "../assets/images/about/schoolF.svg";
import schoolG from "../assets/images/about/schoolG.svg";
import schoolH from "../assets/images/about/schoolH.png";
import schoolI from "../assets/images/about/schoolI.png";
import schoolJ from "../assets/images/about/schoolJ.png";
import schoolK from "../assets/images/about/schoolK.svg";
import schoolL from "../assets/images/about/schoolL.png";

import wsA from "../assets/images/about/WS1.webp";
import wsB from "../assets/images/about/WS2.webp";
import wsC from "../assets/images/about/WS3.webp";
import wsD from "../assets/images/about/WS4.webp";
import wsE from "../assets/images/about/WS5.webp";
import wsF from "../assets/images/about/WS6.webp";
import wsG from "../assets/images/about/WS7.webp";
import wsH from "../assets/images/about/WS8.webp";

// Course
import courseLanding from "../assets/images/course/courseLanding.webp";
import quoteIcon from "../assets/images/course/quoteicon.svg";

// LMS

import dummyCourse from "../assets/images/lms/dummycourse.jpg";
import filter from "../assets/images/lms/filter.svg";
import accounting from "../assets/images/lms/accounting.svg";
import economics from "../assets/images/lms/economics.svg";
import mathematics from "../assets/images/lms/mathematics.svg";
import science from "../assets/images/lms/science.svg";
import teacherStudent from "../assets/images/lms/teacherstudent.png";
import course from "../assets/images/lms/course.png";
import person from "../assets/images/lms/person.png";
import profileBg from "../assets/images/lms/profilebg.webp";

import purchaseHistory from "../assets/images/lms/icons/purchaseHistory.svg";
import changePassword from "../assets/images/lms/icons/changePassword.svg";
import deleteAccount from "../assets/images/lms/icons/deleteAccount.svg";
import faqs from "../assets/images/lms/icons/faqs.svg";
import helpCenter from "../assets/images/lms/icons/helpCenter.svg";
import logout from "../assets/images/lms/icons/logout.svg";
import notification from "../assets/images/lms/icons/notification.svg";

import dribble from "../assets/images/lms/icons/dribble.svg";
import emptyNotification from "../assets/images/lms/icons/emptyNotification.svg";
import history from "../assets/images/lms/history.png";
import module from "../assets/images/lms/module.png";
import certificate from "../assets/images/lms/certificate.png";
import lessonVideo from "../assets/images/lms/lessonvideo.png";
import lessonQuize from "../assets/images/lms/lessonquize.png";
import childSketch from "../assets/images/lms/childsketch.png";
import paySuccess from "../assets/images/lms/paysuccess.png";
import profileAvtar from "../assets/images/lms/profileAvtar.png";

import mahesh from "../assets/images/about/Mahesh_nambir.jpg";
import purnima from "../assets/images/about/PurnimaNambir.jpg";

// Offerings page
import intervention from "../assets/images/offerings/intervention.webp";
import courses from "../assets/images/offerings/Courses.webp";
import consultancy from "../assets/images/offerings/Consultancy.webp";
import coaching from "../assets/images/offerings/Coaching.webp";

import offeringA from "../assets/images/offerings/Offerings1.webp";
import offeringB from "../assets/images/offerings/Offerings2.webp";

import consultancyA from "../assets/images/offerings/Consultancy1.webp";
import consultancyB from "../assets/images/offerings/Consultancy2.webp";
import consultancyC from "../assets/images/offerings/Consultancy3.webp";
import consultancyD from "../assets/images/offerings/Consultancy4.webp";

import interventionA from "../assets/images/offerings/interventionA.png";

//Offering Icons
import interventionIcon from "../assets/images/home/InterventionIcon.png";
import consultancyIcon from "../assets/images/home/ConsultancyIcon.png";

export const images = {
  logo,

  // Logogs
  unbound,
  vnv,
  informatic,
  ruchi,
  tallyon,

  interventionIcon,
  consultancyIcon,

  zigzag,
  successSketch,
  loginBanner,
  fnf,
  beSpoke,

  // Home
  getApp,
  reward,
  homeLanding,
  recapHand,

  // Blog
  blog,
  corner,
  viewBlog,

  blogA,
  blogB,
  blogC,
  blogD,
  blogE,
  blogF,
  blogG,
  blogH,
  blogI,
  blogJ,
  blogK,
  blogL,

  // Career
  career,
  careerArrow,
  imgSlideA,
  imgSlideB,
  imgSlideC,
  imgSlideD,
  imgSlideE,

  // About
  mahesh,
  purnima,
  aboutA,
  aboutB,
  aboutC,

  wsA,
  wsB,
  wsC,
  wsD,
  wsE,
  wsF,
  wsG,
  wsH,

  aboutLandingA,
  aboutLandingB,
  aboutLandingC,

  aboutLandingArrowA,
  aboutLandingArrowB,
  aboutLandingArrowC,

  storyA,
  storyB,
  storyC,

  schoolA,
  schoolB,
  schoolC,
  schoolD,
  schoolE,
  schoolF,
  schoolG,
  schoolH,
  schoolI,
  schoolJ,
  schoolK,
  schoolL,

  // Course
  courseLanding,
  quoteIcon,

  // LMS
  filter,
  accounting,
  economics,
  mathematics,
  science,
  teacherStudent,
  course,
  person,
  profileBg,

  purchaseHistory,
  changePassword,
  deleteAccount,
  faqs,
  helpCenter,
  logout,
  notification,

  dribble,
  emptyNotification,
  history,
  module,
  certificate,
  lessonVideo,
  lessonQuize,
  childSketch,
  paySuccess,
  profileAvtar,
  dummyCourse,

  // Offering

  intervention,
  courses,
  consultancy,
  coaching,

  offeringA,
  offeringB,

  interventionA,

  consultancyA,
  consultancyB,
  consultancyC,
  consultancyD,
};
